// $(document).foundation();

//document ready
$(document).ready(function () {

	var App = {

		//init Function
		init: function () {
			App.footerYear();
			App.otherStuff();
		},

		//footer year
		footerYear: function () {
			var dateNow = new Date();
			var fullYear = dateNow.getFullYear();
			$('#year').html(fullYear);
		},

		//other plugins
		otherStuff: function () {
			//open link in new tab
			$('a[href^="http://bloomnet.eu"], .list_partners a, .fb').attr('target', '_blank');

      //copy widget text
      $('.copy').bind('click', copy_text);
      function copy_text(e) {
				e.preventDefault();
        var el = $(this);
        var text = el.parents('.snippet').find('.snippet__desc').text();
        var textArea = document.createElement('textarea');

        el.addClass('bounceIn');
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand('Copy');
        textArea.remove();

        setTimeout(function(){
          el.removeClass('bounceIn')
        }, 1250);
      }

			//niceSelect init
			if ($('.select').length == true) {
				$('.select').niceSelect();
			}
    },

	}

	$(function () {
		App.init();
	});

});
